:root {
  --primary: rgb(11, 192, 223);
  --secondary: #004aad;
  --danger: #fa5656;
  --success: #28C76F;
}

/* Makes the body of the content box editor take up the whole card */
.card-body .mce-content-body {
    position: absolute;
    top: 10px;
    left: 10px;
    right: 10px;
    bottom: 10px;
    overflow: auto;
}

/* Makes the placeholder of the content box editor take up the whole card - you can click anywhere in an empty box */
.mce-content-body[data-mce-placeholder] {
    position: absolute !important;
    top: 10px;
    left: 10px;
    right: 10px;
    bottom: 30px;
    /* Other styles you want to apply */
  }

/* Makes the toolbar appear above the contentbox */
.tox {
    z-index: 2000;
}

/* Makes the cursor in an empty content box visible */
.mce-content-body p {
    margin-left: 5px;
}

/* Makes the placeholder in an empty content box line up with the cursor adjustment from above */
.mce-content-body:not([dir=rtl])[data-mce-placeholder]:not(.mce-visualblocks)::before {
    left: 5px !important;
}

/* Without this you can't resize table columns. https://github.com/tinymce/tinymce/issues/2877 */
[class*=ephox-snooker], .ephox-dragster-blocker {
    z-index: 9998 !important;
}

/* Makes sure the cards in the editAnnouncementForm look right. */
.card-header {
    min-height: 90px;
}

#landing-feature {
    min-height: 0;
}

/* The next two rules move the little triangle below the +Add Box popup to the other side */
.dropdown .dropdown-menu[x-placement=top-start]:before, .dropdown .dropdown-menu[x-placement=top-end]:before, .dropup .dropdown-menu:before {
    right: 160px
}
.dropdown .dropdown-menu[x-placement=top-start]:after, .dropdown .dropdown-menu[x-placement=top-end]:after, .dropup .dropdown-menu:after {
    right: 160px
}

button[type=button] {
    background: rgb(11, 192, 223);
    border: 0;
}
.btn, .navbar .navbar-nav > a.btn{
    border-width: 2px;
    font-weight: 600;
    font-size: 14px;
    line-height: 1.35em;
    text-transform: uppercase;
    border-radius: 3px;
    padding: 11px 12px;
    cursor: pointer;
    background-color: rgb(11, 192, 223);
    color: #FFFFFF;
    transition: all 150ms linear;
    height: 41px;
}

.present-style-row .card {
  margin-bottom: 0px;
}

.bottom-navbar {
    margin: 3px 1px;
    /* border-top-right-radius: 15px;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px; */
    height: 50px;
    /* outline: 1px solid #ddd; */
}
.btn:hover {
    color: #fff;
    background-color: #545b62;
    border-color: #545b62;
    /* outline: 1px solid #ddd !important; */
}
.dropdown-menu .dropdown-item {
    color: #545b62;
    font-size: 14px;
    padding: 10px 45px 10px 15px;
    clear: both;
    white-space: nowrap;
    width: 100%;
    display: block;
    background: transparent;
}

.dropdown-menu {
    border: none;
    outline: 1px solid #ddd;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
    background-color: #545b62;
}

.btn-primary:focus, .btn-primary.focus {
    color: #fff;
    background-color: rgb(11, 192, 223);
    border-color: rgb(11, 192, 223);
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
}

/* .box-show {
    background-color: rgb(11, 192, 223) !important;
    border: 2px solid rgb(11, 192, 223);
    width: 150px;
} */

.box-show {
  background-color: rgb(11, 192, 223) !important;
  border-top: 2px solid rgb(11, 192, 223);
  width: 120px;
  border-left: 0px !important;
  border-right: 0px !important;
  height: 50px;
}

.box-show:hover {
    background-color: #545b62 !important;
    width: 120px;
    border-top: 2px solid rgb(11, 192, 223)
    
}

.box-show:focus {
    background-color: rgb(11, 192, 223) !important;
    color: #fff !important;
    border-top: 2px solid rgb(11, 192, 223);
    width: 120px;
}

/* .box-hide {
    background-color: transparent !important;
    color: rgb(11, 192, 223);
    border: 2px solid rgb(11, 192, 223) !important;
    border-top-width: 0px !important;
    width: 150px;
    outline: 1px solid #ddd;
} */

.box-hide {
  background-color: transparent !important;
  color: rgb(11, 192, 223);
  border-top: 2px solid rgb(11, 192, 223) !important;
  /* border-top-width: 0px !important; */
  width: 120px;
  border-left: 0px !important;
  border-right: 0px !important;
  height: 50px;
  /* outline: 1px solid #ddd; */
}


.btn-group .btn + .btn {
    margin-left: -3px;
    /* border: 2px solid #0bc0df; */
}

.box-hide:hover {
    background-color: #545b62 !important;
    width: 120px;
}

.box-hide:focus {
    background-color: transparent !important;
    color: rgb(11, 192, 223) !important;
    border: 2px solid rgb(11, 192, 223) !important;
    width: 120px;
}

.launch-button{
    padding: 2px 14px;
    display: flex; 
    align-items: center;
    justify-content: center;
    border-left: 5px solid #004aad !important;
    border-right: 5px solid #004aad !important; 
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    height: 62px;
    margin: -5px 25px 0px 0px;
    font-size: 20px;
    outline: 1px solid #ddd;
    box-shadow: inset 0px 0px 6px black;
}

.launch-button:hover {
    padding: 2px 14px;
    display: flex; 
    align-items: center;
    justify-content: center;
    border-left: 5px solid #004aad !important;
    border-right: 5px solid #004aad !important; 
    border-top-left-radius: 25px;
    border-top-right-radius: 25px;
    height: 62px;
    margin: -5px 25px 0px 0px;
    font-size: 20px;
    outline: 1px solid #ddd;
    box-shadow: inset 0px 0px 6px black !important;
}

/* .btn-icon {
    background-color: transparent !important;
    color: black;
} */

.landing-navbar {
    margin-bottom: 0px !important;
}

.landing-btn-no-hover:hover {
    color: rgb(11, 192, 223) !important;
}

.btn-icon {
    font-size: x-large;
    border-radius: 50px;
    background-color: rgb(228, 98, 98);
    color:rgb(78, 78, 78)
}

.btn-neutral{
    font-size: x-large;
    border-radius: 50px;
    background-color: transparent !important;
    color:rgb(78, 78, 78)
}

.carousel-img {
    width: 100vw;
    height: auto;
}

#move-box-left-right {
    padding: 0px 0px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 46px;
}

/* #move-box-left {
    margin-right: 15px;
} */

#move-box-left {
  margin: 0px;
  /* bottom: 35%; */
  border: none;
  color: white !important;
  right: 5px;
}

#move-box-left:hover {
    /* border: 2px solid white !important; */
    color: white !important;
    right: 5px;
}
#move-box-left:disabled {
    border: none !important;
    color: transparent !important;
    right: 5px;
}

/* #move-box-right {
    margin-left: 15px;
    border: none;
} */

#move-box-right {
  margin: 0px;
  /* bottom: 35%; */
  border: none;
  color: white !important;
  right: 5px;
}
#move-box-right:hover {
    /* border: 2px solid white !important; */
    color: white !important;
    right: 5px;

}
#move-box-right:disabled {
    border: none !important;
    color: transparent !important;
    right: 5px;

}

.no-margin-p > p{
    margin: 0px !important;
}

.clock {
    text-align: center;
    font-size: 45px;
    font-feature-settings: 'tnum';
}

.stopwatch-digits {
    font-feature-settings: 'tnum';
}

.timer-digits {
    font-feature-settings: 'tnum';
    margin-bottom: 0px;
}

.logo-col.col-md-2 {
    padding-right: 0px;
}

.clock-col.col-md-2 {
    padding-left: 0px;
}

.randomizer .modal-dialog {
    margin-left: 100px;
    margin-top: 0px;
}

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 835px;
        margin: 1.75rem auto;
    }
}

@media (min-width: 576px) {
    .whiteboard .modal-dialog {
        max-width: 835px;
        margin: 2rem;
    }
}

@media (max-width: 1500px) {
    .clock {
        font-size: 35px;
    } 
}


@media (max-width: 1250px) {
    .landing-page-macbook {
        width: 80%;
        height: auto;
        position: absolute;
        left: 50%;
        transform: translate(-50%, 85%)
    }  

    .clock {
        font-size: 30px
    }
}

@media (max-width: 900px) {
    .landing-page-macbook {
        width: 90%;
        height: auto;
        position: absolute;
        left: 50%;
        transform: translate(-50%, 90%)
    }  
}

@media (max-width: 650px) {
    .landing-page-macbook {
        width: 95%;
        height: auto;
        position: absolute;
        left: 50%;
        transform: translate(-50%, 95%)
    }  
}

@media (max-width: 500px) {
    .landing-page-macbook {
    width: 100%;
    height: auto;
    position: absolute;
    left: 50%;
      transform: translate(-50%, 100%);
    }
  }

  .modal-header .close {
    padding: 3px 8px;
    margin: -1rem -1rem -1rem auto;
    background: transparent;
}




.react-toggle {
    touch-action: pan-x;
  
    display: inline-block;
    position: relative;
    cursor: pointer;
    background-color: transparent;
    border: 0;
    padding: 0;
  
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-tap-highlight-color: transparent;
}
  
  .react-toggle-screenreader-only {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }
  
  .react-toggle--disabled {
    cursor: not-allowed;
    opacity: 0.5;
    -webkit-transition: opacity 0.25s;
    transition: opacity 0.25s;
  }
  
  .react-toggle-track {
    width: 50px;
    height: 24px;
    padding: 0;
    border-radius: 30px;
    background-color: #4D4D4D;
    -webkit-transition: all 0.2s ease;
    -moz-transition: all 0.2s ease;
    transition: all 0.2s ease;
  }
  
  .react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: #000000;
  }
  
  .react-toggle--checked .react-toggle-track {
    background-color: rgb(11, 192, 223);
  }
  
  .react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: rgb(11, 192, 223);
  }
  
  .react-toggle-track-check {
    position: absolute;
    width: 14px;
    height: 10px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    left: 8px;
    opacity: 0;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
  }
  
  .react-toggle--checked .react-toggle-track-check {
    opacity: 1;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
  }
  
  .react-toggle-track-x {
    position: absolute;
    width: 10px;
    height: 10px;
    top: 0px;
    bottom: 0px;
    margin-top: auto;
    margin-bottom: auto;
    line-height: 0;
    right: 10px;
    opacity: 1;
    -webkit-transition: opacity 0.25s ease;
    -moz-transition: opacity 0.25s ease;
    transition: opacity 0.25s ease;
  }
  
  .react-toggle--checked .react-toggle-track-x {
    opacity: 0;
  }
  
  .react-toggle-thumb {
    transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1) 0ms;
    position: absolute;
    top: 1px;
    left: 1px;
    width: 22px;
    height: 22px;
    border: 1px solid #4D4D4D;
    border-radius: 50%;
    background-color: #FAFAFA;
  
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  
    -webkit-transition: all 0.25s ease;
    -moz-transition: all 0.25s ease;
    transition: all 0.25s ease;
  }
  
  .react-toggle--checked .react-toggle-thumb {
    left: 27px;
    border-color: rgb(11, 192, 223);
  }
  
  .react-toggle--focus .react-toggle-thumb {
    -webkit-box-shadow: 0px 0px 3px 2px #004aad;
    -moz-box-shadow: 0px 0px 3px 2px #004aad;
    box-shadow: 0px 0px 2px 3px #004aad;
  }
  
  .react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
    -webkit-box-shadow: 0px 0px 5px 5px #004aad;
    -moz-box-shadow: 0px 0px 5px 5px #004aad;
    box-shadow: 0px 0px 5px 5px #004aad;
  }

  .article-subheadline {
    margin-bottom: 5px;
    margin-top: 35px;
  }

 .custom-modal {
    position: fixed;       
    top: 22% !important;
    left: 950px !important;
    transform: translate(-50%);
 }
 .center-top {
    position: fixed;
    top: -30px;
    left: 31%;
    width: auto;
    height: auto;
 }

 .center-middle {
    position: fixed;
    top: 120px;
    left: 31%;
    width: auto;
    height: auto;
 }

 .center-bottom {
    position: fixed;
    top: auto;
    bottom: 45px;    
    left: 31%;
    width: auto;
    height: auto;
 }
 
 .right-top {
    position: fixed;
    top: -30px;
    left: auto;
    right: -182px;
    width: auto;
    height: auto;
 }

 .right-top-auto-music {
  position: fixed;
  top: -30px;
  left: auto;
  right: 20px;
  width: auto;
  height: auto;
}

 .right-middle {
    position: fixed;
    top: 120px;
    left: auto;
    right: -182px;
    width: auto;
    height: auto;
 }

 .right-middle-auto-music {
  position: fixed;
  top: 120px;
  left: auto;
  right: 20px;
  width: auto;
  height: auto;
}

 .right-bottom {
    position: fixed;
    top: auto;
    bottom: 45px;
    left: auto;
    right: -182px;
    width: auto;
    height: auto;
 }

 .right-bottom-auto-music {
  position: fixed;
  top: auto;
  bottom: 45px;
  left: auto;
  right: 20px;
  width: auto;
  height: auto;
}

 .auto-music-player-modal {
  background-color: white;
  border-radius: 8px 0px 0px 8px;
  border: 1px solid lightgrey;
  border-right: none;
  width: 120px;
  height: 60px;
  position: fixed;
  top: auto;
  bottom: 10%;
  right: -130px; /* Start position (hidden off-screen) */
  left: auto;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.15), 0 0 1px 1px rgba(0, 0, 0, 0.1);
  animation: slideIn 0.5s ease-out forwards; /* Apply animation */
}

 .left-top {
  position: fixed;
  top: -30px;
  left: 100px;
  width: auto;
  height: auto;
 }

 .left-middle {
  position: fixed;
  top: 120px;
  left: 100px;
  width: auto;
  height: auto;
 }

 .left-bottom {
  position: fixed;
  top: auto;
  bottom: 45px;
  left: 100px;
  width: auto;
  height: auto;
 }

 .full-screen {
  position: fixed;
  left: 80px;
  width: 95vw;
  height: 93vh;
 }

 .custom-modal-content {
    width: 75% !important
 }

 .youtubeLinkModal {
    width: 25%;
    position: fixed;       
    top: 22% !important;
    left: 900px !important;
    transform: translate(-50%);
 }

.whiteboard-modal {
    width: 96vw;
    height: 92vh;
    padding-top: 0px !important;
}

.whiteboard .modal-dialog {
    padding-top: 0px !important;
}

.randomizer-modal {
    width: 90vw;
    height: 85vh;
    padding-top: 0px !important;
    overflow: scroll;
}

.present-style-row {
    position: inherit;
    bottom: 75px;
    left: 110px;
    right: 30px;
    display: flex;
    flex-direction: column;
    z-index: 1000;
}

.present-style-grid {
    position: fixed;
    /* bottom: 60px; */
    left: 110px;
    right: 30px;
    display: grid;
    /* grid-template-columns: 1fr 1fr; */
    grid-gap: 10px;
    z-index: 1000;
}
.main-panel-dark {
    position: relative;
    float: right;
    background-color: black;
    transition: all 0.5s cubic-bezier(0.685, 0.0473, 0.346, 1);
}

@media (min-width: 991px) {
    .sidebar-mini .main-panel-dark {
        width: calc(100% - 80px);
    }
}
@media (min-width: 991px) {
    .sidebar-mini .main-panel-dark {
        width: calc(100% - 80px);
    }
}
@media (min-width: 991px) {
    .sidebar, .main-panel-dark, .sidebar-wrapper {
        transition-property: top, bottom, width;
        transition-duration: 0.2s, 0.2s, 0.35s;
        transition-timing-function: linear, linear, ease;
        -webkit-overflow-scrolling: touch;
    }
}

.wrapper-dark {
    position: relative;
    top: 0;
    height: 100vh;
    overflow-x: hidden;
    background-color: black;
}

/* .dropup.show .dropdown-menu[x-placement=bottom-start] {
    top: auto !important;
    bottom: 0 !important;
    transform: translate3d(-20px, -50px, 0) !important;
} */

.carousel .carousel-inner {
    box-shadow: none;
    /* display: flex;
    align-items: center;
    justify-content: center; */
}

.carousel-indicators li {
    background-color: #004aad;
}

.image-container {
    position: relative;
    overflow: hidden;
  }
  
  .image-container img {
    display: block;
    width: 100%;
    height: auto;
  }
  
  .image-container:hover img {
    border: 2px solid black; /* Adjust the border properties as needed */
  }

  #google-slides-button {
      background-color: rgb(11, 192, 223) !important
  }

  .alert.alert-with-icon {
      padding: 10px;
      text-align: center;
  }

  .excel-navigation {
    width: 100%;
    overflow: hidden;
  }
  
  .tabs-container {
    white-space: nowrap;
    overflow-x: auto;
    padding-left: 40px;
    padding-right: 40px;
  }
  
  .tab {
    display: inline-block;
    padding: 10px 20px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    cursor: pointer;
    width: 150px;
    text-align: center;
  }
  
  .scroll-btn {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 40px;
    background-color: #f0f0f0;
    border: 1px solid #ccc;
    cursor: pointer;
  }
  
  .scroll-btn.prev {
    left: 0;
  }
  
  .scroll-btn.next {
    right: 0;
  }

  .PanelResizeHandle i {
    display: none;
  }
  
  /* Show the icon when the parent is hovered */
  .PanelResizeHandle:hover i {
    display: flex; /* or 'flex' if you need it to be a flex container */
  }

  .progress {
    background-color: #DDDDDD;
    border-radius: 5px;
    box-shadow: none;
    height: 20px;
   }

   /* .rdtPicker table {
    width: 50%;
   }

   .rdtCounters > div {
    margin: 0px;
   }

   .rdt .rdtPicker {
    width: 60%;
   } */

   .inputWithUnit {
    display: flex;
    align-items: center;
    font-size: x-small;
  }
  
  .inputWithUnit input {
    text-align: center;
  }
  
  .inputWithUnit span {
    margin-left: 4px; /* Adjust as needed */
  }

  .rdtPicker {
    width: 173px;
    border-radius: 8px;
  }

  td.rdtDay.rdtActive.rdtToday {
    border-radius: 8px;
  }

  .rdtCounterSeparator {
      padding-left: 0px;
      padding-right: 0px;
  }

  .rdtStatic .rdtPicker {
    position: relative;
  }

  .present-date-picker {
      width: 100%
  }

  .present-date-picker input {
    text-align: center;
    height: 45px;
    background-color: rgb(11, 192, 223);
    border: none;
    color: white;
    font-size: 16px;
    font-weight: 600;
  }

  .present-date-picker .rdtPicker {
      top: -293px;
      right: -20px;
      width: 200px;
      border: 1px solid lightgrey;
  }

  .present-date-picker .rdtPicker:before {
    top: 287px;
    left: 70px;
    transform: rotate(180deg);
  }

  .present-date-picker .rdtPicker:after {
    top: 287px;
    left: 70px;
    transform: rotate(180deg);
  }

  .present-date-picker.content-boxes-table {
    margin-top: 15px;
  }

  .present-navbar-launch-section {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding-left: 125px;
    display: flex;
    z-index: 1000;
    background-color: white;
    border-top: 1px solid #ddd;
    height: 52px;
    width: 20%;
  }

  .present-navbar-tabs-section {
    display: flex;
    flex-direction: row;
    position: fixed;
    bottom: 0;
    width: 68%;
    left: 20%;
    right: 15%;
    z-index: 1000;
    background-color: white;
    border-top: 1px solid #ddd;
    height: 52px;
    overflow-x: scroll;
    overflow-y: visible;
    white-space: nowrap;
    padding-right: 10px;
    border-left: 1px solid lightgrey;
  }

  .present-navbar-datepicker-section {
    position: fixed;
    bottom: 0;
    right: 0;
    display: flex;
    align-content: center;
    justify-content: center;
    z-index: 1000;
    background-color: white;
    border-top: 1px solid #ddd;
    height: 52px;
    width: 14%;
    border-left: 1px solid lightgrey;
    align-items: center;
    padding-right: 10px;
  }

  @media (max-width: 1600px) {
    .present-navbar-launch-section {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        padding-left: 125px;
        display: flex;
        z-index: 1000;
        background-color: white;
        border-top: 1px solid #ddd;
        height: 52px;
        width: 22%;
    }

    .present-navbar-tabs-section {
        display: flex;
        flex-direction: row;
        position: fixed;
        bottom: 0;
        width: 64%;
        left: 22%;
        right: 15%;
        z-index: 1000;
        background-color: white;
        border-top: 1px solid #ddd;
        height: 52px;
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
        padding-right: 10px;
        border-left: 1px solid lightgrey;
    }

    .present-navbar-datepicker-section {
        position: fixed;
        bottom: 0;
        right: 0;
        display: flex;
        align-content: center;
        justify-content: center;
        z-index: 1000;
        background-color: white;
        border-top: 1px solid #ddd;
        height: 52px;
        width: 16%;
        border-left: 1px solid lightgrey;
        align-items: center;
        padding-right: 10px;
      }
  }

  @media (max-width: 1400px) {
    .present-navbar-launch-section {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        padding-left: 125px;
        display: flex;
        z-index: 1000;
        background-color: white;
        border-top: 1px solid #ddd;
        height: 52px;
        width: 24%;
    }

    .present-navbar-tabs-section {
        display: flex;
        flex-direction: row;
        position: fixed;
        bottom: 0;
        width: 61%;
        left: 24%;
        right: 15%;
        z-index: 1000;
        background-color: white;
        border-top: 1px solid #ddd;
        height: 52px;
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
        padding-right: 10px;
        border-left: 1px solid lightgrey;
    }

    .present-navbar-datepicker-section {
        position: fixed;
        bottom: 0;
        right: 0;
        display: flex;
        align-content: center;
        justify-content: center;
        z-index: 1000;
        background-color: white;
        border-top: 1px solid #ddd;
        height: 52px;
        width: 17.25%;
        border-left: 1px solid lightgrey;
        align-items: center;
        padding-right: 10px;
      }
  }

  @media (max-width: 1200px) {
    .present-navbar-launch-section {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        padding-left: 125px;
        display: flex;
        z-index: 1000;
        background-color: white;
        border-top: 1px solid #ddd;
        height: 52px;
        width: 27%;
    }

    .present-navbar-tabs-section {
        display: flex;
        flex-direction: row;
        position: fixed;
        bottom: 0;
        width: 55%;
        left: 27%;
        right: 15%;
        z-index: 1000;
        background-color: white;
        border-top: 1px solid #ddd;
        height: 52px;
        overflow-x: scroll;
        overflow-y: hidden;
        white-space: nowrap;
        padding-right: 10px;
        border-left: 1px solid lightgrey;
    }

    .present-navbar-datepicker-section {
        position: fixed;
        bottom: 0;
        right: 0;
        display: flex;
        align-content: center;
        justify-content: center;
        z-index: 1000;
        background-color: white;
        border-top: 1px solid #ddd;
        height: 52px;
        width: 21%;
        border-left: 1px solid lightgrey;
        align-items: center;
        padding-right: 10px;
      }
  }

  .marquee-container {
      overflow: hidden;
  }

  .marquee-card {
    height: 95px;
    margin-bottom: 8px;
  }

  .clock-card {
    height: 95px;
    margin-bottom: 8px;
  }

  @media (max-width: 1600px) {
    .marquee-card {
        height: 90px;
    }

    .clock-card {
        height: 90px
    }    
  }

  @media (max-width: 1500px) {
    .marquee-card {
        height: 85px;
    }

    .clock-card {
        height: 85px
    }
    
  }

  .font-size-picker .btn {
      width: 100%;
      margin: 0px;
      height: 38px;
      background: transparent;
      border: solid 1px lightgray;
      color:rgb(78, 78, 78);
      overflow: hidden;
  }

  .font-size-picker .btn:hover {
    width: 100%;
    margin: 0px;
    height: 38px;
    background: transparent;
    border: solid 1px lightgray;
    color:rgb(78, 78, 78) !important;
    overflow: hidden;
}

  .font-size-picker .show > .btn.dropdown-toggle {
    width: 100%;
    margin: 0px;
    height: 38px;
    background: transparent;
    border: solid 1px lightgray;
    color:rgb(78, 78, 78) !important;
    overflow: hidden;
  }

  /* .font-size-picker .dropdown.show .dropdown-menu[x-placement=top-start] {
    transform: translate3d(0px, 30px, 0) !important;
    bottom: auto !important;
    top: 0 !important;
    height: 200px !important;
    overflow: scroll !important;
  } */

  /* .font-size-picker-menu .dropdown.show .dropdown-menu[x-placement=top-start] {
    transform: translate3d(0px, 30px, 0) !important;
    bottom: auto !important;
    overflow: scroll !important;
    height: 200px !important;
    left: -12px !important;
    margin-top: 0px !important;
    top: -12px !important;
  }

  .font-size-picker-menu .dropdown.show .dropdown-menu[x-placement=bottom-start] {
    transform: translate3d(0px, 30px, 0) !important;
    bottom: auto !important;
    overflow: scroll !important;
    height: 200px !important;
    left: -12px !important;
    margin-top: 0px !important;
    top: -12px !important;
  } */

  div.font-size-picker-menu.dropdown-menu.show {
    transform: translate3d(0px, 50px, 0) !important;
    bottom: auto !important;
    overflow: scroll !important;
    height: 200px !important;
    width: 100%;
    left: 1px !important;
    margin-top: 0px !important;
    top: -12px !important;
    border-radius: 8px;
  }

  div.font-size-picker-menu-real.dropdown-menu.show {
    transform: translate3d(0px, 50px, 0) !important;
    bottom: auto !important;
    overflow: scroll !important;
    height: 200px !important;
    width: 100%;
    left: 1px !important;
    margin-top: 0px !important;
    top: -12px !important;
    min-width: 50px;
    border-radius: 8px;
  }

  div.line-height-picker-menu.dropdown-menu.show {
    transform: translate3d(0px, 50px, 0) !important;
    bottom: auto !important;
    overflow: scroll !important;
    height: 180px !important;
    width: 100%;
    left: 1px !important;
    margin-top: 0px !important;
    top: -12px !important;
    min-width: 50px;
  }

  /* div.font-size-picker-menu-real.dropdown-menu.show {
    transform: translate3d(0px, 50px, 0) !important;
    bottom: auto !important;
    overflow: scroll !important;
    height: 200px !important;
    width: 100%;
    left: 1px !important;
    margin-top: 0px !important;
    top: -12px !important;
    min-width: 50px;
  } */

  div.font-align-picker-menu.dropdown-menu.show {
    transform: translate3d(0px, 50px, 0) !important;
    bottom: auto !important;
    overflow: scroll !important;
    /* height: 100px !important; */
    width: 100%;
    left: 1px !important;
    margin-top: 0px !important;
    top: -12px !important;
    min-width: 80px;
  }

  div.launchButtonDropdown.dropdown-menu.show {
    transform: translate3d(-20px, -60px, 0) !important;
    /* bottom: auto !important;
    overflow: scroll !important;
    height: 200px !important;
    width: 100%;
    left: 1px !important;
    margin-top: 0px !important;
    top: -12px !important; */
  }

  div.bottom-navbar-tab-menu.dropdown-menu.show {
    transform: translate3d(22px, -40px, 0) !important;
  }

  /* .font-size-picker-menu.dropdown-menu.show {
    transform: translate3d(0px, 30px, 0) !important;
    bottom: auto !important;
    overflow: scroll !important;
    height: 200px !important;
    left: -12px !important;
    margin-top: 0px !important;
    top: -12px !important;
  }

  .font-size-picker-menu {
    transform: translate3d(0px, 30px, 0) !important;
    bottom: auto !important;
    overflow: scroll !important;
    height: 200px !important;
    left: -12px !important;
    margin-top: 0px !important;
    top: -12px !important;
  }

  .font-size-picker .dropdown.show .dropdown-menu[x-placement=bottom-start] {
    transform: translate3d(0px, 30px, 0) !important;
    bottom: auto !important;
    top: 0 !important;
    height: 200px !important;
    overflow: scroll !important;
  }

  .font-size-picker-menu .dropdown.show .dropdown-menu {
    transform: translate3d(0px, 30px, 0) !important;
    bottom: auto !important;
    top: 0 !important;
    overflow: scroll !important;
    height: 200px !important;
    left: -12px !important;
    margin-top: 0px !important;
    top: -12px !important;
  } 

  .font-size-picker-menu .dropdown.show .dropdown-menu[x-placement=bottom-start] {
    transform: translate3d(0px, 30px, 0) !important;
    bottom: auto !important;
    top: 0 !important;
    overflow: scroll !important;
    height: 200px !important;
    left: -12px !important;
    margin-top: 0px !important;
    top: -12px !important;
  } */

  .agenda-edit-modal {
    min-width: 92vw;
    margin-right: 1vw;
    padding-top: 0px;
  }

  .launchButtonDropdown .dropdown.show .dropdown-menu[x-placement=top-start] {
    transform: translate3d(0px, 30px, 0) !important;
    bottom: auto !important;
    top: 0 !important;
    height: 200px !important;
    overflow: scroll !important;
  }

  /* TipTapEditor */

.toolbar {
  display: flex;
  flex-wrap: wrap;
  gap: 0.1rem;
  padding: .25rem;
  margin-bottom: 1rem;
  background-color: white;
  border: 1px solid lightgrey;
  border-radius: 5px;
  transform: translate();
}

.toolbar-group {
  display: flex;
  flex-wrap: nowrap;
}

.toolbar-spacer {
  width: 2px;
  margin-left: 2px;
  margin-right: 2px;
  margin-top: 2px;
  margin-bottom: 2px;
  background-color: lightgrey;
}

.toolbar-spacer-white {
  width: 5px;
}

.toolbar button {
  border: none;
  padding: 0.2rem .5rem;
  background: white;
  cursor: pointer;
  border-radius: 3px;
  height: 38px;
  width: 38px;
  font-size: 18px;
}

.toolbar button.is-active {
  background: #559ce7;
  color: #fff;
}

.toolbar button:hover {
  background: #559ce7;
  color: #fff;
}

.toolbar .line-height-picker.dropdown {
  border: none;
  background-color: white;
  border-radius: 3px;
  width: max-content;
}

.toolbar .line-height-picker.dropdown button {
  border: none;
  padding: 0.2rem .5rem;
  background: white;
  cursor: pointer;
  border-radius: 3px;
  color: black;
  margin: 0px;
  width: 45px;
}

.toolbar .line-height-picker.dropdown button:hover {
  background: #559ce7;
  color: #fff;
}

.ProseMirror {        
  min-height: 100%;
  max-height: 100%;
  min-width: 100%;
  overflow: scroll;
  padding: 8px
}

/* .toolbar .dropdown:hover {
  background: #559ce7;
  color: #fff;
} */

#editor-content {
  border: 1px solid #ccc;
  padding: 1rem;
}

ul[data-type="taskList"] {
  list-style: none;
  padding: 0;
}

ul[data-type="taskList"] p {
  margin: 0;
}

ul[data-type="taskList"] li {
  display: flex;
}

ul[data-type="taskList"] li > label {
  flex: 0 0 auto;
  margin-right: 0.5rem;
  user-select: none;
}

ul[data-type="taskList"] li > div {
  flex: 1 1 auto;
}

ul[data-type="taskList"] ul li,
ul[data-type="taskList"] ol li {
  display: list-item;
}

ul[data-type="taskList"] ul[data-type="taskList"] > li {
  display: flex;
}

/* --------------------------------------*/

.editor-container {
  position: relative;
  padding: 1px;
  border-radius: 5px;
}

.floating-toolbar {
  position: fixed;
  display: flex;
  flex-direction: column; /* Stack rows vertically */
  gap: 0.2rem;
  padding: 0.25rem;
  background-color: white;
  border: 1px solid lightgrey;
  border-radius: 5px;
  z-index: 9999; /* Ensure the toolbar is on top of other elements */
}

.toolbar-row {
  display: flex;
  flex-wrap: nowrap;
  gap: 0.2rem;
}

.floating-toolbar-single-line {
  top: 2px !important;
  position: fixed;
  display: flex;
  padding: 0.25rem;
  background-color: white;
  border: 1px solid lightgrey;
  border-radius: 5px;
  z-index: 9999;
  width: 410px;
  max-width: 100%; /* Ensure it doesn't overflow the viewport */
  overflow-x: clip;
}

.toolbar-row-single-line {
  position: relative;
  overflow-y: visible;
  display: flex;
  flex: 1;
  flex-wrap: nowrap;
  gap: 0.2rem;
}

.toolbar-row-single-line div {
  flex: 0 0 auto; /* Ensure items don't shrink */
}

.toolbar-row-single-line button {
  border: none;
  background: white;
  cursor: pointer;
  border-radius: 3px;
  height: 30px;
  width: 30px;
  font-size: 18px;
  flex: 0 0 auto; /* Ensure items don't shrink */
}

.toolbar-row-single-line button:hover {
  background: #559ce7;
  color: white;
}

.toolbar-row-single-line .table-options-picker.dropdown button {
  border: none;
  padding: 0.2rem .5rem;
  background: white;
  cursor: pointer;
  border-radius: 3px;
  color: black;
  margin: 0px;
  width: 50px
}

.toolbar-row-single-line .line-height-picker.dropdown button {
  border: none;
  padding: 0.2rem .5rem;
  background: white;
  cursor: pointer;
  border-radius: 3px;
  color: black;
  margin: 0px;
  width: 50px
}

.floating-toolbar button {
  border: none;
  background: white;
  cursor: pointer;
  border-radius: 3px;
  height: 30px;
  width: 30px;
  font-size: 18px;
}

.floating-toolbar .is-active {
  background: #559ce7;
  color: white;
}

.floating-toolbar button:hover {
  background: #559ce7;
  color: white;
}

.floating-toolbar button:disabled:hover {
  background: lightgrey;
  color: white;
}

.floating-toolbar select {
  padding: 0.5rem;
}

.floating-toolbar .line-height-picker.dropdown {
  border: none;
  background-color: white;
  border-radius: 3px;
  width: max-content;
}

.floating-toolbar .line-height-picker.dropdown button {
  border: none;
  padding: 0.2rem .5rem;
  background: white;
  cursor: pointer;
  border-radius: 3px;
  color: black;
  margin: 0px;
  width: 50px
}

.floating-toolbar .line-height-picker.dropdown button:hover {
  background: #559ce7;
  color: white;
}

.floating-toolbar .table-options-picker.dropdown {
  border: none;
  background-color: white;
  border-radius: 3px;
  width: max-content;
}

.floating-toolbar .table-options-picker.dropdown button {
  border: none;
  padding: 0.2rem .5rem;
  background: white;
  cursor: pointer;
  border-radius: 3px;
  color: black;
  margin: 0px;
  width: 50px
}

.floating-toolbar .table-options-picker.dropdown button:hover {
  background: #559ce7;
  color: white;
}

div.table-options-picker-menu.dropdown-menu.show {
  transform: translate3d(0px, 50px, 0) !important;
  bottom: auto !important;
  overflow: scroll !important;
  height: 300px !important;
  width: 170px;
  left: 1px !important;
  margin-top: 0px !important;
  top: -12px !important;
  min-width: 170px;
}

.table-options-menu-option.dropdown-item {
  width: 170px !important;
}

.font-style-picker .btn {
  width: 100%;
  margin: 0px;
  height: 30px;
  background: transparent;
  border: solid 1px lightgray;
  color: rgb(78, 78, 78) !important;
  overflow: hidden;
  padding: 0px;
  font-size: 14px;
}

.font-style-picker-announcements.dropdown {
  height: 0px
}

.font-style-picker-announcements .btn {
  width: 100%;
  margin: 0px;
  margin-top: -20px;
  height: 30px;
  background: transparent;
  border: solid 1px lightgray;
  color: rgb(78, 78, 78) !important;
  overflow: hidden;
  padding: 0px;
  font-size: 14px;
}

.floating-toolbar .line-height-picker.dropdown .dropdown-item:hover {
  min-width: 80px; 
}

/* ------------ tiptap table ------------- */

.tiptap table {
  border-collapse: collapse;
  margin: 0;
  overflow: hidden;
  table-layout: fixed;
  width: 100%;
}

.tiptap table td,
.tiptap table th {
  border: 2px solid #ced4da;
  box-sizing: border-box;
  min-width: 1em;
  padding: 3px 5px;
  position: relative;
  vertical-align: top;
}

.tiptap table td > *,
.tiptap table th > * {
  margin-bottom: 0;
}

.tiptap table th {
  background-color: #f1f3f5;
  font-weight: bold;
  text-align: left;
}

.tiptap table .selectedCell:after {
  background: rgba(200, 200, 255, 0.4);
  content: "";
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  pointer-events: none;
  position: absolute;
  z-index: 2;
}

.tiptap table .column-resize-handle {
  background-color: #adf;
  bottom: -2px;
  position: absolute;
  right: -10px;
  pointer-events: none;
  top: 0;
  width: 4px;
}

.tiptap table p {
  margin: 0;
}

.tableWrapper {
  padding: 1rem 0;
  overflow-x: auto;
}

.resize-cursor {
  cursor: ew-resize;
  cursor: col-resize;
}

/* ------- Edit Table Right Click Menu ------- */

.context-menu {
  position: absolute;
  background-color: rgb(255, 255, 255);
  border: 1px solid lightgrey;
  border-radius: 5px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
}

.context-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.context-menu li {
  padding: 8px 12px;
  cursor: pointer;
}

.context-menu li:hover {
  background-color: #f1f1f1;
}

/* --- Checkboxes need to match font size of text after them --- */



/* ---------------------------- */

.ProseMirror-widget::before {
  display: none
}

/* -------------END TEXT EDITOR CSS--------------- */

.contentBoxTableDatePicker {
  height: 51px;
  display: flex;
  align-content: center;
  justify-content: center;
}

.contentBoxTableDatePicker .rdtPicker {
  top: 38px;
  font-size: 14px;
}

.contentBoxTableDatePicker .rdtPicker:before {
  top: -8px;
  transform: none;
}

.contentBoxTableDatePicker .rdtPicker:after {
  top: -8px;
  transform: none;
}

.printDatePicker {
  height: 51px;
  display: block;
}

.printDatePicker input {
  border-radius: 8px;
}

.printDatePicker .form-control {
  width: 100%;
}

.printDatePicker .rdtPicker {
  top: 45px;
  font-size: 14px;
  width: 200px;
}

.printDatePicker .rdtPicker:before {
  top: -8px;
  transform: none;
}

.printDatePicker .rdtPicker:after {
  top: -8px;
  transform: none;
}

/* -------------FILE PICKER CSS--------------- */

.file-container {
  display: inline-block;
  margin: 10px;
  text-align: center;
  cursor: pointer;
}

.file-image {
  border: 1px solid grey;
  height: 125px;
  width: 125px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.file-image img {
  height: 100%;
  width: 100%;
  border-radius: 10px;
  object-fit: cover;
}

.file-container:hover .file-image {
  border: 2px solid blue;
}

.file-container.selected .file-image {
  border: 2px solid blue;
}

.file-name {
  font-size: 12px;
  max-width: 125px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* -------------END FILE PICKER CSS--------------- */

.print-view .nc-icon:hover {
  font-weight: bolder
}

.launch-modal .nc-icon:hover {
  font-weight: bolder
}

#contentToPrint h4 {
  margin-top: 12px;
  margin-bottom: 7px;
}

@keyframes pulseBorder {
  0% {
    border-color: lightgrey;
  }
  50% {
    border-color: blue;
  }
  100% {
    border-color: lightgrey;
  }
}

.pulse-border {
  border: 3px solid lightgrey; /* Initial border settings */
  animation: pulseBorder 1s infinite; /* Animation settings */
}

.bootstrap-switch.bootstrap-switch-on .bootstrap-switch-primary {
  background-color: rgb(11, 192, 223);
}

.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-default {
  background-color: #9e9e9e;
}

.react-toggle-track {
  background-color: #9e9e9e;
}

.react-resizable-handle {
  position: absolute;
  width: 30px;
  height: 30px;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA2IDYiIHN0eWxlPSJiYWNrZ3JvdW5kLWNvbG9yOiNmZmZmZmYwMCIgeD0iMHB4IiB5PSIwcHgiIHdpZHRoPSI2cHgiIGhlaWdodD0iNnB4Ij48ZyBvcGFjaXR5PSIwLjMwMiI+PHBhdGggZD0iTSA2IDYgTCAwIDYgTCAwIDQuMiBMIDQgNC4yIEwgNC4yIDQuMiBMIDQuMiAwIEwgNiAwIEwgNiA2IEwgNiA2IFoiIGZpbGw9IiMwMDAwMDAiLz48L2c+PC9zdmc+');
  background-position: bottom right;
  margin: 0 -5px -5px 0;
  zoom: 2;
}

/* -------------Hiding scrollbars--------------- */

.sidebar-mini .sidebar .sidebar-wrapper {
  overflow: hidden
}

.no-boxes-set-to-show-alert {
  margin-top: 3px;
  background-color: transparent !important;
  color: rgb(11, 192, 223);
  border-top: 2px solid rgb(11, 192, 223) !important;
  /* border-top-width: 0px !important; */
  width: 120px;
  border-left: 0px;
  border-right: 0px;
  height: 50px;
  animation: pulseBorderNoBoxesToShowAlert 1s infinite; /* Animation settings */
  /* outline: 1px solid #ddd; */
}

@keyframes pulseBorderNoBoxesToShowAlert {
  0% {
  }
  50% {
    text-shadow: white 0 0 5px;
    color: var(--secondary);
  }
  100% {
  }
}

.no-active-boxes-alert {
  animation: pulseBorderNoBoxesToShowAlert 1s infinite; /* Animation settings */
}

.empty-agenda-box-alert {
  animation: pulseEmptyAgendaBoxAlert 1s infinite; /* Animation settings */
}

@keyframes pulseEmptyAgendaBoxAlert {
  0% {
  }
  50% {
    box-shadow: blue 0 0 20px;
    color: white
  }
  100% {
  }
}

/* Hide scrollbars globally */
::-webkit-scrollbar {
  display: none;
}

/* Re-enable scrollbars for the agenda-body element */
.agenda-body {
  overflow-y: auto !important; /* Ensure vertical scrolling */
  overflow-x: hidden; /* Hide horizontal scrolling, optional */
  scrollbar-width: auto; /* Firefox support */
  scrollbar-color: auto; /* Firefox support */
}

/* Webkit-specific scrollbar customization for agenda-body */
.agenda-body::-webkit-scrollbar {
  display: block; /* Re-enable scrollbar */
  width: 8px; /* Set scrollbar width */
}

.agenda-body::-webkit-scrollbar-thumb {
  background: #ccc; /* Scrollbar thumb color */
  border-radius: 4px; /* Optional: Round edges */
}

.agenda-body::-webkit-scrollbar-track {
  background: #f0f0f0; /* Scrollbar track color */
}

/* Re-enable scrollbars for the agenda-body element */
.font-size-picker-menu {
  border-radius: 2px;
  overflow-y: auto !important; /* Ensure vertical scrolling */
  overflow-x: hidden; /* Hide horizontal scrolling, optional */
  scrollbar-width: auto; /* Firefox support */
  scrollbar-color: auto; /* Firefox support */
}

/* Webkit-specific scrollbar customization for agenda-body */
.font-size-picker-menu::-webkit-scrollbar {
  height: 0px;
  display: block; /* Re-enable scrollbar */
  width: 8px; /* Set scrollbar width */
}

.font-size-picker-menu::-webkit-scrollbar-thumb {
  background: #ccc; /* Scrollbar thumb color */
  border-radius: 4px; /* Optional: Round edges */
}

.font-size-picker-menu::-webkit-scrollbar-track {
  background: #f0f0f0; /* Scrollbar track color */
}


/* Re-enable scrollbars for the agenda-body element */
.font-size-picker-menu-real {
  border-radius: 2px;
  overflow-y: auto !important; /* Ensure vertical scrolling */
  overflow-x: hidden; /* Hide horizontal scrolling, optional */
  scrollbar-width: auto; /* Firefox support */
  scrollbar-color: auto; /* Firefox support */
}

/* Webkit-specific scrollbar customization for agenda-body */
.font-size-picker-menu-real::-webkit-scrollbar {
  height: 0px;
  display: block; /* Re-enable scrollbar */
  width: 8px; /* Set scrollbar width */
}

.font-size-picker-menu-real::-webkit-scrollbar-thumb {
  background: #ccc; /* Scrollbar thumb color */
  border-radius: 4px; /* Optional: Round edges */
}

.font-size-picker-menu-real::-webkit-scrollbar-track {
  background: #f0f0f0; /* Scrollbar track color */
}

@keyframes flash {
  0% { background-color: rgb(255, 255, 255); }
  50% { background-color: rgba(255, 0, 0, 0); }
  100% { background-color: rgb(255, 255, 255); }
}

.flash-effect {
  animation: flash 1s infinite;
}

@keyframes randomizerFlash {
  0% { text-shadow: rgb(255, 0, 0) 0 0 10px; }
  20% { text-shadow: rgb(251, 255, 0) 0 0 10px; }
  40% { text-shadow: rgb(0, 255, 34) 0 0 10px; }
  60% { text-shadow: rgb(0, 247, 255) 0 0 10px; }
  80% { text-shadow: blue 0 0 10px; }
  100% { text-shadow: rgb(255, 57, 239) 0 0 10px; }
}

.randomizer-flash {
  animation: randomizerFlash 1s infinite;
}

.collapse-scroll {
  max-height: 300px; /* Set your desired max height */
  overflow-y: auto;  /* Enables vertical scrolling */
}

/* .scroll-text-in-sidebar {
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.2s ease-in, visibility 0s linear; 
} 

.sidebar:hover .scroll-text-in-sidebar {
  opacity: 1;
  visibility: visible;
  transition-delay: 0.2s;
}
*/

.scroll-text-in-sidebar {
  opacity: 0.7;
  visibility: hidden;
  max-height: 0;
  overflow: hidden;
  transition: opacity 0.2s ease-in, max-height 0.3s ease-in-out, visibility 0s linear 0s; /* Visibility changes instantly */
  font-size: 10px;
  margin: 0px 0px 0px 35px;
  color: #66615B;
  text-decoration: underline;
  cursor: pointer;
}

.sidebar:hover .scroll-text-in-sidebar {
  opacity: 1;
  visibility: visible;
  max-height: 100px; /* Adjust to fit the actual height of your content */
  transition-delay: 0.2s; /* Delay only for expanding (opacity) */
}

.admin-panel-kpi-tiles {
  border:  2px solid rgb(11, 192, 223);
  padding: 10px;
}

.admin-panel-kpi-tiles h4 {
  margin: 0px 0px 5px 0px;
}

.sidebar-hidden {
  visibility: hidden;
  position: fixed;
  top: 0;
  height: 100%;
  bottom: 0;
  width: 260px;
  left: 0;
  z-index: 999;
}

.toolbar-single-line-scroll-button {
  width: 15px !important;
  color: rgb(11, 192, 223);
  border: none;
  background: white;
  cursor: pointer;
  border-radius: 3px;
  height: 30px;
  font-size: 18px;
  flex: 0 0 auto;
  flex-shrink: 0;
}

.toolbar-single-line-scroll-button:hover {
  background: #559ce7;
  color: white;
}

.toolbar-single-line-scroll-button-right {
  width: 15px !important;
  color: rgb(11, 192, 223);
  border: none;
  background: white;
  cursor: pointer;
  border-radius: 3px;
  height: 30px;
  font-size: 18px;
  flex: 0 0 auto;
  margin-left: auto; /* Push it to the far right */
}

.toolbar-single-line-scroll-button-right:hover {
  background: #559ce7;
  color: white;
}

.fullscreen-modal .modal-dialog {
  width: 98.5vw;
  margin: 10px;
  max-width: none !important;
  padding: 0px;
}

.fullscreen-modal .modal-body {
  height: 93vh;
}

.active-dates-modal-header.modal-header .close {
  transform: translateY(-110%);
  font-size: 50px;
}

.agenda-edit-modal-header.modal-header {
  padding: 5px 15px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
  height: 70px;
}

.agenda-edit-modal-header.modal-header .close {
  transform: translateY(-90%);
  font-size: 50px;
}

.agenda-edit-modal input.form-control {
  border-radius: 8px;
  border: 1px solid #ccc;
}

.agenda-edit-modal label {
  font-weight: bold
}

div.agenda-body.modal-body > div > div.col-md-7 button {
  border-radius: 8px;
}

.background-modal-header.modal-header {
  padding: 10px 15px;
}

.background-modal-header.modal-header .close {
  font-size: 50px;
}

.clock-version-modal .modal-header {
  padding: 5px 15px;
  height: 70px;
  border-bottom: none;
}

.clock-version-modal .modal-header .close {
  transform: translateY(-90%);
  font-size: 50px;
}

.clock-version-modal label {
  font-weight: bold
}

.clock-version-modal #exampleColor {
  border-radius: 8px;
}

.clock-version-modal #language {
  border-radius: 8px;
}

.content-box-storage-table, .content-box-storage-table-active-dates {
  width: 1200px !important;
  top: 0px !important;
  left: 450px !important;
  transform: translate(-50%);
  position: relative;
  max-height: 86vh;
}

.content-box-storage-table .modal-header {
  padding: 10px 15px;
  height: 35px;
  border-bottom: none;
}

.content-box-storage-table .modal-header .close {
  /* transform: translateY(-10%); */
  font-size: 50px;
}

.content-box-storage-table-active-dates .modal-header {
  padding: 10px 15px;
  height: 50px;
  border-bottom: none;
}

.content-box-storage-table-active-dates .modal-header .close {
  transform: translateY(-90%);
  font-size: 50px;
}

.settings-did-you-mean-to-save-modal .modal-header .close {
  transform: translateY(-100%);
  font-size: 50px;
}

.settings-did-you-mean-to-save-modal button {
  padding: 10px 30px;
  border-radius: 30px;
  font-size: 1.1rem;
  font-weight: bold;
}

#accordion  i {
  color: rgb(11, 192, 223);
  font-size: 30px;
  margin-right: 20px;
}

#accordion  a {
  font-size: 16px;
}

#default-pickers .btn.dropdown-toggle {
  border-radius: 8px;
  border: 1px solid #ccc;
}

.launch-modal {
  padding-top: 10px;
}

.launch-modal .modal-header {
  border-bottom: none;
  padding: 10px 15px;
}

.launch-modal .modal-header .close {
  transform: translateY(10%);
  font-size: 50px;
}

.open-widget-modal .modal-header {
  border-bottom: none;
  padding: 10px 15px;
}

.open-widget-modal .modal-header .close {
  transform: translateY(10%);
  font-size: 50px;
}

.fullscreen-modal .modal-header {
  border-bottom: none;
  padding: 3px 15px;
}

.fullscreen-modal .modal-header .close {
  transform: translateY(20%);
  font-size: 50px;
}

.widget-modal .modal-header {
  padding: 10px 15px;
}

.widget-modal .modal-header .close {
  font-size: 50px;
}

.content-box-preview-modal .modal-header {
  border-bottom: none;
  padding: 3px 15px;
}

.content-box-preview-modal .modal-header .close {
  transform: translateY(20%);
  font-size: 50px;
}

.auto-music-player-modal {
  background-color: white;
  border-radius: 8px 0px 0px 8px;
  border: 1px solid lightgrey;
  border-right: none;
  width: 120px;
  height: 60px;
  position: fixed;
  top: auto;
  bottom: 10%;
  right: -130px; /* Start position (hidden off-screen) */
  left: auto;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.15), 0 0 1px 1px rgba(0, 0, 0, 0.1);
  animation: slideIn 0.5s ease-out forwards; /* Apply animation */
}

/* 🔹 Animation Keyframes */
@keyframes slideIn {
  from {
    right: -130px; /* Start position (off-screen) */
  }
  to {
    right: 0; /* End position (fully visible) */
  }
}


.auto-music-player-modal .modal-content {
  background-color: transparent;
  border: none;
  box-shadow: none;
}

.auto-music-player-modal button{
  border-radius: 40px;
  height: 40px;
  width: 40px;
  font-size: 20px;
  margin: 0px;
  padding: 0px;
}

.auto-music-player-modal .btn + .btn{
  margin-left: 10px;
}

.auto-music-player-modal button i {
  margin-left: 4px;
}

.add-edit-form-close-toggle .close {
  transform: translateY(-100%);
  font-size: 50px;
}

.add-edit-announcement-modal {
  width: 1200px !important;
  top: 0px !important;
  left: 450px !important;
  transform: translate(-50%);
  position: relative;
}

.add-edit-announcement-modal .modal-header {
  border-bottom: none;
  padding: 10px 15px;
  height: 50px;
}

.add-edit-announcement-modal .modal-header .close {
  transform: translateY(-100%);
  font-size: 50px;
}

.edit-auto-happy-birthday-modal .modal-header {
  border-bottom: none;
  padding: 10px 15px;
  height: 100px;
}

.edit-auto-happy-birthday-modal .modal-header .close {
  transform: translateY(-170%);
  font-size: 50px;
}

.add-edit-playlist-modal {
  width: 1200px !important;
  top: 0px !important;
  left: 450px !important;
  transform: translate(-50%);
  position: relative;
}

.add-edit-playlist-modal .modal-header {
  border-bottom: none;
  padding: 10px 15px;
  height: 50px;
}

.add-edit-playlist-modal .modal-header .close {
  transform: translateY(-100%);
  font-size: 50px;
}

.add-edit-countdown-modal {
  width: 800px !important;
  top: 0px !important;
  left: 450px !important;
  transform: translate(-50%);
  position: relative;
}

.add-edit-countdown-modal .modal-header {
  border-bottom: none;
  padding: 10px 15px;
  height: 50px;
}

.add-edit-countdown-modal .modal-header .close {
  transform: translateY(-100%);
  font-size: 50px;
}

.add-edit-randomizer-list-modal {
  width: 800px !important;
  top: 0px !important;
  left: 450px !important;
  transform: translate(-50%);
  position: relative;
}

.add-edit-randomizer-list-modal .modal-header {
  border-bottom: none;
  padding: 10px 15px;
  height: 50px;
}

.add-edit-randomizer-list-modal .modal-header .close {
  transform: translateY(-100%);
  font-size: 50px;
}

.latest-updates-modal .modal-header {
  border-bottom: none;
  padding: 5px 15px;
  height: 60px;
}

.latest-updates-modal .modal-header .close {
  transform: translateY(-90%);
  font-size: 50px;
}

.full-screen-modal-content {
  top: -50px;
  left: auto;
  right: 250px;
  height: 85vh;
  width: 88vw;
}

.video-modal .modal-header {
  background-color: black;
  border-bottom: 0px;
}

.video-modal .modal-header .close {
  color: white;
  opacity: 1 !important;
}

.video-modal .modal-body {
  padding: 0px; 
  background-color: black;
}

div.countdown-timer-modal-location-picker.dropdown-menu.show {
  transform: translate3d(0px, 50px, 0) !important;
  bottom: auto !important;
  overflow: scroll !important;
  height: 360px !important;
  width: 100%;
  left: 1px !important;
  margin-top: 0px !important;
  top: -12px !important;
  border-radius: 10px;
}

/* Re-enable scrollbars for the agenda-body element */
.countdown-timer-modal-location-picker {
  border-radius: 2px;
  overflow-y: auto !important; /* Ensure vertical scrolling */
  overflow-x: hidden; /* Hide horizontal scrolling, optional */
  scrollbar-width: auto; /* Firefox support */
  scrollbar-color: auto; /* Firefox support */
}

/* Webkit-specific scrollbar customization for agenda-body */
.countdown-timer-modal-location-picker::-webkit-scrollbar {
  height: 0px;
  display: block; /* Re-enable scrollbar */
  width: 8px; /* Set scrollbar width */
}

.countdown-timer-modal-location-picker::-webkit-scrollbar-thumb {
  background: #ccc; /* Scrollbar thumb color */
  border-radius: 4px; /* Optional: Round edges */
}

.countdown-timer-modal-location-picker::-webkit-scrollbar-track {
  background: #f0f0f0; /* Scrollbar track color */
}

.add-row-to-playlist-col {
  cursor: pointer;
  border-radius: 8px;
  height: 40px;
  color: #004aad;
  font-size: 16px;
  text-align: center;
  margin: 2px 15px;
  padding: 10px;
  border: 1px solid #004aad;
}

.add-row-to-playlist-col:hover {
  background-color: rgb(11, 192, 223);
  color: white;
  border: none;
}

.add-row-to-playlist-col-disabled {
  cursor: not-allowed;
  border-radius: 8px;
  height: 40px;
  color: #818181;
  font-size: 16px;
  text-align: center;
  margin: 2px 15px;
  padding: 10px;
  border: 1px solid #818181;
}

/* Webkit-specific scrollbar customization for agenda-body */
.playlist-table::-webkit-scrollbar {
  display: block; /* Re-enable scrollbar */
  width: 8px; /* Set scrollbar width */
}

.playlist-table::-webkit-scrollbar-thumb {
  background: #ccc; /* Scrollbar thumb color */
  border-radius: 4px; /* Optional: Round edges */
  
}

.playlist-table::-webkit-scrollbar-track {
  background: #f0f0f0; /* Scrollbar track color */
  margin-top: 45px;
  border-top: 1px solid #ddd;
}

/* ***************** ActiveDatesDatePicker CSS Start ***************** */

.date-picker-button {
  background-color: rgb(11, 192, 223);
  height: 45px;
  padding: 0px 5px;
  margin: 0px;
  border-radius: 25px;
}

.date-picker-button:focus {
  background-color: rgb(11, 192, 223);
  height: 45px;
  padding: 0px 5px;
  margin: 0px;
  border-radius: 25px;
}

.date-picker-button:hover {
  background-color: rgb(11, 192, 223);
  height: 45px;
  padding: 0px 5px;
  margin: 0px;
  border-radius: 25px;
}

.date-picker-button.left {
  border-radius: 25px 0px 0px 25px;
}

.date-picker-button.right {
  border-radius: 0px 25px 25px 0px;
}

.date-picker-button i {
  font-size: 20px;
  padding: 0px;
  margin: 0px;
}

.date-picker-button i:hover {
  color: #004aad;
}

.date-display {
  margin-top: 0px;
  cursor: pointer;
  background-color: rgb(11, 192, 223);
  color: white;
  padding: 0px 8px;
  text-align: center;
  height: 45px;
}

.date-display:hover {
  color: #004aad;
}

.day-name {
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 600;
}

.date-string {
  font-size: 14px;
}

.date-string.small-text {
  font-size: 12px;
}

.rdtDay {
  color: var(--secondary);
}

.rdtDay.rdtToday.rdtActive, .rdtDay.rdtActive, .rdtDay.rdtActive:hover {
  background-color: var(--primary) !important;
}

.rdtPicker .dow, .rdtPicker th.rdtSwitch, .rdtPicker th.rdtNext, .rdtPicker th.rdtPrev, .rdtPicker .rdtTimeToggle {
  color: var(--secondary);
}

/* ***************** ActiveDatesDatePicker CSS End ***************** */

.btn-neutral.btn-info {
  color: var(--primary) !important;
}

.text-primary {
  color: var(--primary) !important;
}

.icon-edit {
  color: var(--primary) !important;
}

.icon-delete {
  color: var(--danger) !important;
}

.button-delete {
  background-color: var(--danger) !important;
}

.button-success {
  background-color: var(--success) !important;
}

.sidebar[data-active-color=info] .nav li.active > a, .sidebar[data-active-color=info] .nav li.active > a i, .sidebar[data-active-color=info] .nav li.active > a[data-toggle=collapse], .sidebar[data-active-color=info] .nav li.active > a[data-toggle=collapse] i, .sidebar[data-active-color=info] .nav li.active > a[data-toggle=collapse] ~ div > ul > li.active .sidebar-mini-icon, .sidebar[data-active-color=info] .nav li.active > a[data-toggle=collapse] ~ div > ul > li.active > a, .off-canvas-sidebar[data-active-color=info] .nav li.active > a, .off-canvas-sidebar[data-active-color=info] .nav li.active > a i, .off-canvas-sidebar[data-active-color=info] .nav li.active > a[data-toggle=collapse], .off-canvas-sidebar[data-active-color=info] .nav li.active > a[data-toggle=collapse] i, .off-canvas-sidebar[data-active-color=info] .nav li.active > a[data-toggle=collapse] ~ div > ul > li.active .sidebar-mini-icon, .off-canvas-sidebar[data-active-color=info] .nav li.active > a[data-toggle=collapse] ~ div > ul > li.active > a {
    color: var(--primary) !important;
}

/* ***************** Animated H1 for Landing Page Start ***************** */

.animated-text-container {
  text-align: center;
  overflow: hidden;
}

.animated-text {
  color: rgb(11, 192, 223);
  font-weight: 1000;
  font-size: 8rem;
  text-shadow: 2px 2px 0px #004aad;
  opacity: 0; /* Start hidden */
  transform: translateX(100px); /* Start off-screen to the right */
  animation: slideInLandingPage 0.7s ease-out forwards;
  margin: -20px;
}

/* Staggered delays */
.plan {
  animation-delay: 0.2s;
}

.present {
  animation-delay: 0.5s;
}

.automate {
  animation-delay: 0.8s;
}

/* Slide-in animation */
@keyframes slideInLandingPage {
  from {
    opacity: 0;
    transform: translateX(100px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

/* ***************** Animated H1 for Landing Page End ***************** */

/* ***************** This styles the input below color picker used in text-editor *************** */

.color-picker body {
  margin: 0;
  padding: 50px;
  font: normal 16px "PT Mono", monospace;
}

.color-picker input {
  display: block;
  box-sizing: border-box;
  width: 90px;
  margin: 20px 55px 0;
  padding: 6px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: #eee;
  outline: none;
  font: normal 16px "PT Mono", monospace;
  text-transform: uppercase;
  text-align: center;
}

.color-picker input:focus {
  border-color: #4298ef;
}


@keyframes pulse-for-landing-page-button {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.07); /* Slightly bigger */
  }
  100% {
    transform: scale(1);
  }
}

.pulsing-button {
  animation: pulse-for-landing-page-button 1.5s infinite ease-in-out;
}

.button-fixed:hover {
  background-color: var(--secondary);
  color: white !important;
}

.button-inverted {
  background-color: white;
  border: 2px solid var(--primary);
  color: var(--primary);
  border-radius: 8px;
}

.button-inverted:hover {
  background-color: white;
  border: 2px solid var(--secondary);
  color: var(--secondary) !important;
}

.mobile-view-input-addon {
  width: 90px;
  border-top: solid #66615b;
  border-left: solid #66615b;
  border-bottom: solid #66615b;
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
}

/* ***************** Adding Horizontal Scrollbar for bottom nav Start ***************** */

.present-navbar-tabs-section-with-scroll::-webkit-scrollbar {
  display: block; /* Re-enable scrollbar */
  width: 8px; /* Set scrollbar width */
  height: 10px;
}

.present-navbar-tabs-section-with-scroll::-webkit-scrollbar-thumb {
  background: #ccc; /* Scrollbar thumb color */
  border-radius: 4px; /* Optional: Round edges */
}

.present-navbar-tabs-section-with-scroll::-webkit-scrollbar-track {
  background: white; /* Scrollbar track color */
  border-top: 1px solid #ccc;
}

.bottom-navbar-with-scroll {
  height: 40px !important;
}

.present-navbar-tabs-section-with-scroll #move-box-left {
  bottom: 13%;
}

.present-navbar-tabs-section-with-scroll #move-box-right {
  bottom: 13%;
}

.present-navbar-tabs-section-with-scroll button {
  padding-top: 0px;
}

button.btn.bottom-navbar.box-hide:hover {
  background-color: var(--primary) !important;
}

button.btn.bottom-navbar.box-show:hover {
  background-color: white !important;
  color: var(--primary) !important;
}

@keyframes pulseForLaunchButton {
  0% { transform: scale(1); }
  50% { transform: scale(1.05); }
  100% { transform: scale(1); }
}

.btn.bottom-navbar.launch-button.btn.btn-secondary:active {
  background-color: var(--primary) !important;
}

.btn.bottom-navbar.launch-button.btn.btn-secondary:focus {
  background-color: var(--primary) !important;
}

.btn.bottom-navbar.launch-button.btn.btn-secondary:hover {
  background-color: var(--primary) !important;
  animation: pulseForLaunchButton 0.5s ease-in-out;
}

.present-navbar-launch-section #caret:hover img {
  animation: pulseForLaunchButton 0.5s ease-in-out;
}

.present-navbar-launch-section #caret:hover div {
  animation: pulseForLaunchButton 0.5s ease-in-out;
}

.button-hover-fix:hover {
  background-color: var(--secondary) !important;
  /* transform: scale(1.05); */
}

.button-hover-fix:focus {
  background-color: var(--primary) !important;
  /* transform: scale(1.05); */
}

.button-hover-fix:focus:hover {
  background-color: var(--secondary) !important;
  /* transform: scale(1.05); */
}

.delete-button {
  background-color: var(--danger) !important;
  /* transform: scale(1.05); */
}

.delete-button:hover {
  background-color: #e04b4b !important;
  /* transform: scale(1.05); */
}

.delete-button:focus {
  background-color: var(--danger) !important;
  /* transform: scale(1.05); */
}

.delete-button:focus:hover {
  background-color: #e04b4b !important;
  /* transform: scale(1.05); */
}

/* ***************** Adding Horizontal Scrollbar for bottom nav End ***************** */

.custom-tooltip {
  opacity: 1 !important; /* Fully remove transparency */
}

.custom-tooltip .tooltip-inner {
  padding: 15px !important; /* More spacing */
  color: black; /* Optional: Text color */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2); /* Optional: Add shadow */
  border-radius: 10px; /* Optional: Rounded corners */
  background-color: white !important; /* Solid background */
  opacity: 1 !important; /* Fully remove transparency */
  max-width: 650px !important; /* Expand tooltip width */
}

.onboarding-wizard-modal {
  margin: 0px;
}

.onboarding-wizard-modal-child {
  padding-top: 0px;
}

.content-box-table-header .form-check-input {
  margin-left: 0px
}

.content-box-table-header .btn-group > div {
  border-radius: 8px;
}

.content-box-table-header .btn-group > .multi-select-hover-div:hover {
  background-color: #dbdbdb !important;
}

.content-box-table-body > tr {
  height: 50px;
}

.content-box-table-body > tr > td {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.content-boxes-table .modal-dialog {
  margin-top: 0px;
}
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@900&family=Roboto&display=swap');
